<template>
    <div>
    <advice-form
      :is-advice-form-sidebar-active.sync="isAdviceFormSidebarActive"
      :headertext="HeaderText"
      :advice-data="AdviceData"
      @refetch-data="fetchAdviceData" 
    />
    <b-card no-body>
        <div class="m-2 p-1">
            <b-row>
                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    <h3 class="text-dark">จัดการคำแนะนำ</h3>
                </b-col>
                <b-col
                        cols="12"
                        md="6"
                >
                <div class="d-flex align-items-center justify-content-end">
                    
                    <b-button 
                        @click="addAdvice"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-success">
                        <feather-icon icon="PlusIcon" />
                        เพิ่มคำแนะนำ
                    </b-button>

                  
                    
                </div>
                    
                </b-col>
            </b-row>
        </div>
        <div class="ml-2 mr-2 mb-2">
            <b-row class="form-boder-input">
                <b-col
                md="4"
                >
                    <b-form-group
                        label="ค้นหาคำแนะนำ"
                        label-for="search"
                        >
                        <b-input-group class="input-group-merge">
                            <b-form-input name="search" v-model="searchQuery" />
                            <b-input-group-append is-text>
                                <feather-icon icon="SearchIcon" color="#58ADEB" />
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>

            </b-row>
        </div>
        <div class="m-2">
            
            <vue-good-table
            :columns="tableColumns"
            :rows="AdviceListData"
            ref="refListTable"
           
            :select-options="{
                enabled: false,
                selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
              :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
           
            
            >
            <template
            slot="table-row"
            slot-scope="props"
        >       
                <span 
                v-if="props.column.field == 'is_active'"
                class="text-nowrap"
                >
                    <b-badge
                        pill
                        :variant="`light-${resolveUserStatusVariant(props.row.is_active)}`"
                        class="text-capitalize"
                    >
                        {{ resolveUserStatusnameVariant(props.row.is_active) }} 
                    </b-badge>
                </span>
                <span
                v-else-if="props.column.field === 'action'"
                class="text-nowrap"
                >
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-info"
                    class="btn-icon rounded-circle"
                    @click="updateData(props.row)"

                >
                    <feather-icon icon="Edit3Icon" />
                </b-button>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    v-on:click="deleteconfirm(props.row.id,props.row.title)"
                >
                    <feather-icon icon="Trash2Icon" />
                </b-button>

                </span>
            </template>

            <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
             Items per page
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
           
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="AdviceListTotal"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
             
              class="mt-1 mb-0"
            
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
            </vue-good-table>
            
        </div>

    </b-card>
</div>
</template>
<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import store from '@/store'
import useAdviceList from './useAdviceList'
import AdviceForm from './AdviceForm.vue'
import adviceStoreModule from '../adviceStoreModule'
import { ref,onUnmounted } from '@vue/composition-api'
import router from '@/router'
export default {
    components:{
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
        FeatherIcon,BInputGroup,BInputGroupPrepend,BInputGroupAppend,
        VueGoodTable,
        vSelect,
        AdviceForm

    },
    directives:{
        'b-toggle': VBToggle,
        'b-modal': VBModal,
        Ripple

    },
    setup() {
        const HeaderText = ref('')
        const isAdviceFormSidebarActive = ref(false)
        const AdviceData = ref({})
        const PATIENTS_STORE_MODULE_NAME = 'app-advice';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, adviceStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const {
            tableColumns,
            fetchAdviceData,
            pageLength,
            dir,
            AdviceListData,
            AdviceListTotal,
            searchQuery,
            currentPage,
            refListTable,
            deleteAdviceData,
            resolveUserStatusVariant,
            resolveUserStatusnameVariant,
        } = useAdviceList();

        const addAdvice = (data)=>{

        AdviceData.value = {};
        HeaderText.value = 'เพิ่มคำแนะนำ';
        isAdviceFormSidebarActive.value = true;
        }
        const updateData = (data)=>{
            AdviceData.value = data;
            HeaderText.value = 'แก้ไขคำแนะนำ';
            isAdviceFormSidebarActive.value = true;
        }

        return {
            HeaderText,
            isAdviceFormSidebarActive,
            AdviceData,
            tableColumns,
            fetchAdviceData,
            AdviceListData,
            AdviceListTotal,
            pageLength,
            dir,
            searchQuery,
            currentPage,
            refListTable,
            addAdvice,
            updateData,
            deleteAdviceData,
            resolveUserStatusVariant,
            resolveUserStatusnameVariant
        }
    
        
    },methods:{
        handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
        },
        deleteconfirm(id,title){
         this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete advice TITLE: '+title, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
            console.log(id);
           this.deleteAdviceData(id)
          }
        })

        },
    }
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>