import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useAdviceList() {



  const searchQuery = ref('');
  const refListTable = ref(null);
  const currentPage = ref(1);
  const pageLength = ref(10);
  const AdviceListTotal = ref(0);
  const dir = ref(false);
  const toast = useToast()
  const tableColumns = [

      { field: 'id', label: 'id', sortable: false, hidden:true },
        { field: 'title', label: 'คำแนะนำ', sortable: false },

        { field: 'action', label: 'การจัดการ', sortable: false },


    ]
    const AdviceListData = ref([]);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;

    const refetchData = () => {
      refListTable.value.refresh()
  }
    watch([currentPage, pageLength, searchQuery], () => {
      fetchAdviceData();
    })

    const fetchAdviceData = async () => {
        const offset = pageLength.value*(currentPage.value-1);
        AdviceListData.value  = [];
        await store
          .dispatch('app-advice/fetchAdviceData', {
            limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            branch_id:branch_id,
            order_by:'advice.id DESC'
         
          })
          .then(response => {


            AdviceListData.value = response.data.data;
            const total = response.data.total?response.data.total: AdviceListData.value.length;
            AdviceListTotal.value = total
          })
          .catch((error) => {
            console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching advice' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }

  fetchAdviceData();

  const deleteAdviceData = (id) => {
    //fetchDeleteProperty
    var data = {id:id}
    
    var res = store.dispatch('app-advice/deleteAdviceData',data)
    res.then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      fetchAdviceData();
    }).catch(()=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Delete",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
    //console.log('id', id);
    
  }
  const resolveUserStatusVariant = status => {
       
    if (status) return 'success'
    if (!status) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusnameVariant = status => {
  
      if (status) return 'Active'
      if (!status) return 'Inactive'
      return 'primary'
  }

    return {
        fetchAdviceData,
        tableColumns,
        deleteAdviceData,
        AdviceListTotal,
        AdviceListData,
        pageLength,
        dir,
        searchQuery,
        currentPage,
        refListTable,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant,
    }
}